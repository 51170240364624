export const en = {
	// General
	// Dashboard
	DashboardPage: "Dashboard Page",
	last14Days: "Last 14 days",
	Last7Days: "Last 7 days",
	Last48hours: "Last 48 hours",
	Last30Days: "Last 30 Days",
	Last90Days: "Last 90 Days",
	All: "All",
	OverAllSightingsFound: "Overall sightings found",
	SightingsThatArenewlyFound: "Sightings that are newly found",
	SightingsCountThatAreOpen: "Sightings count that are open",
	WorkHours: "Work Hours",
	SumOfWorkingHoursByAllDrivers: "Sum of working hours by all drivers",
	Distance: "Distance",
	OverAllSumOfDistanceCoveredbyAllDrivers: "Overall sum of distance covered by all drivers",
	SuperAdmin: "Super Admin",
	Admin: "Admin",
	Supervisor: "Supervisor",
	Employee: "Employee",
	SightingVolume: "Sighting Volume",
	new: "New",
	open: "Open",
	archived: "Archived",
	mislabeled: "Mislabeled",
	New: "New",
	Open: "Open",
	Archived: "Archived",
	Mislabeled: "Mislabeled",
	Sunday: "Sunday",
	Monday: "Monday",
	Tuesday: "Tuesday",
	Wednesday: "Wednesday",
	Thursday: "Thursday",
	Friday: "Friday",
	Saturday: "Saturday",
	SubMunicipalities: "Sub Municipalities",
	Completed: "Completed",
	District: "District",
	Name: "Name",
	//Sightings Page
	SightingsPage: "Sightings Page",
	Layout: "Layout",
	Custom: "Custom",
	Search: "Search",
	SearchSightingID: "Search for Sighting ID",
	Status: "Status",
	Type: "Type",
	AdvancedSearch: "Advanced Search",
	IncludeConformingGarbageBins: "Include conforming garbage bins",
	Includeconformingbarriers: "Include conforming barriers",
	Polygon: "Polygon",
	SubMunicipality: "Sub Municipality",
	Confidence: "Confidence",
	Timestamp: "Timestamp",
	Clear: "Clear",
	Apply: "Apply",
	TotalResults: "Total Results",
	Volumeofbuildingmaterials: "Volume of building materials",
	Areaofgrafitti: "Area of grafitti",
	m2: "(m2)",
	m3: "(m3)",
	ListView: "List View",
	manual: "Manual",
	ManualTicket: "Manual Ticket",
	automatic: "Automatic",
	ID: "ID",
	DateandTime: "Date and Time",
	AM: "AM",
	PM: "PM",
	Longitude: "Longitude",
	Latitude: "Latitude",
	SpecialAttribute: "Special Attribute",
	Sighting: "Sighting",
	SightingID: "Sighting ID",
	TrainingModel: "Training Model",
	CameraID: "Camera ID",
	Evidence: "Evidence",
	Takemetotrack: "Take me to track",
	Comments: "Comments",
	AddComment: "Add Comment",
	Submit: "Submit",
	Cancel: "Cancel",
	Confirmdelete: "Confirm delete?",
	Areyousuretodeletetheitem: "Are you sure to delete the item?",
	Yes: "Yes",
	No: "No",
	January: "January",
	February: "February ",
	March: "March",
	April: "April",
	May: "May",
	June: "June",
	July: "July",
	August: "August",
	September: "September ",
	October: "October",
	November: "November",
	December: "December",
	Actions: "Actions",
	SightingStatus: "Sighting Status",
	Enteryourmessagehere: "Enter your message here...",
	Openas940Ticket: "Open as 940 Ticket",
	Textcopied: "Text copied",
	NoPolygonFound: "No Polygon Found",
	Back: "Back",
	// Tracks & Coverage Page
	Camera: "Camera",
	SideCamLeft: "SIDE CAM LEFT",
	FrontCam: "FRONT CAM",
	SideCamRight: "SIDE CAM - RIGHT",
	Track: "Track",
	SelectedTrack: "Selected Track",
	TrackStart: "Track Start",
	TrackEndCurrentLocation: "Track End / Current Location",
	Ancient: "Ancient",
	Recent: "Recent",
	km: "km",
	m: "M",
	Start: "Start",
	End: "End",
	AvgSpeed: "Avg. Speed",
	MaxSpeed: "Max Speed",
	Duration: "Duration",
	kmh: "km/h",
	sec: "sec",
	// Live & Planning Page
	IntersectedTrack: "",
	PlannedTrack: "Planned Track",
	IntersectedPlannedTrack: "",
	Live: "Live",
	OnlineDrivers: "Online Drivers",
	AllDrivers: "All Drivers",
	PlannedDistance: "Planned Distance",
	Areas: "Areas",
	Details: "Details",
	CompletedDistance: "Completed Distance",
	ActiveDrivers: "Active Drivers",
	Planning: "Planning",
	StartDate: "Start Date",
	EndDate: "End Date",
	AddNewArea: "Add New Area",
	TotalDistance: "Total Distance",
	AreaID: "Area ID",
	SelectCategory: "Select Category",
	Calculate: "Calculate",
	Schedules: "Schedules",
	AddNewSchedule: "Add New Schedule",
	hrs: "hrs",
	hr: "hr",
	Assignments: "Assignments",
	AddAssignment: "Add Assignment",
	Driver: "Driver",
	ValidFrom: "Valid From",
	ValidTo: "Valid To",
	Reportaproblem: "Report a problem",
	// Settings Page
	Settings: "Setting",
	SuggestPassword: "Suggest Password",
	UserManagement: "User Management",
	Email: "Email",
	Mobile: "Mobile",
	ConfidenceRate: "Confidence Rate",
	ShowConfidenceRate: "Show Confidence Rate",
	SelectedRange: "Selected Range",
	Active: "Active",
	LastSeen: "Last Seen",
	Add: "Add",
	AssignedAt: "Assigned At",
	LastSent: "Last Sent",
	CurrentSentCount: "Current Sent Count",
	DriverLoggedOutSuccessfully: "Driver logged out successfully",
	AutomationSettings: "Automation Settings",
	MaxSightingsPerDay: "Max Sightings Per Day",
	Usecustomlayout: "Use custom layout",
	LastRun: "LastRun",
	Grid: "Grid",
	Destination: "Destination",
	Method: "Method",
	URL: "URL",
	Cars: "Cars",
	CarPlate: "Car Plate",
	Manufacturer: "Manufacturer",
	AllowMultiDrivers: "Allow Multi Drivers",
	Drivers: "Drivers",
	Regular: "Regular",
	CarsAssignments: "Cars Assignments",
	UserRole: "User Role",
	ClassType: "Class Type",
	Rowsperpage: "Rows per page :",
	Of: "of",
	GID: "GID",
	GovernmentID: "Government ID",
	UserUpdatedSuccessfully: "User Updated Successfully",
	UserCreatedSuccessfully: "User Created Successfully",
	EditAutomationSetting: "Edit automation setting",
	NoDataAvailable: "No data available",
	Delete: "Delete",
	Edit: "Edit",
	Download: "Download",
	EditUser: "Edit User",
	// Login Page
	CityKlues: "CityKlues",
	Username: "Username",
	Password: "Password",
	Login: "Login",
	TheSystemofSightinginRiyadh: "The System of Sighting in Riyadh",
	RiyadhRegion: "Riyadh Region",
	Municipality: "Municipality",
	Forgotmypassword: "Forgot my password ?",
	Rememberme: "Remember me",
	AllRightsReserved: "All Rights Reserved",
	LoginSuccessful: "Login Successful",
	Logout: "Logout",
	CarID: "Car ID",
	SightingResults: "Sighting Results",
	NewPassword: "New Password",
	Totalnumberofsightings: "Total number of sightings",
	NewSightings: "New Sightings",
	OpenSightings: "Open Sightings",
	Percentageofscheduledtripsdistancecovered:"Percentage of scheduled trips distance covered",
	Totaldrivingtime: "Total driving time",
	Distancetravelledinkilometres: "Distance travelled in kilometres",
	Total: "Total",
	LeftCam: "LEFT CAM",
	RightCam: "RIGHT CAM",
	PoweredbyKluesforAItechnologiesandIoTSquaredforinfrastructureandoperations:"Powered by Klues for AI technologies, and IoT Squared for infrastructure and operations",
	Metabase: "Metabase",
	SubMunicipalityDistrict: "Sub Municipality / District",
	Sightings: "Sightings",
	TracksPage: "Tracks Page",
	DrawPolygon: "Draw Polygon",
	Filters: "Filters",
	VolumeOfConstructionWaste: "Volume of construction waste",
	PDF: "PDF",
	Excel: "Excel",
	Create: "Create",
	AddUser: "Add User",
	AddAutomationSetting: "Add Automation Setting",
	AreaGreaterThan: "Area greater than",
	VolumeGreaterThan: "Volume greater than",
	AddCar: "Add Car",
	Plate: "Plate",
	Model: "Model",
	Year: "Year",
	Update: "Update",
	Token: "Token ",
	TokenHeader: "Token header",
	AddDestination: "Add Destination",
	EditDestination: "Edit Destination ",
	ScheduleID: "Schedule ID",
	Planned: "Planned",
	Accomplished: "Accomplished",
	Tracking: "Tracking",
	DeletedSuccessfully: "Deleted Successfully",
	Grafitti: "Grafitti",
	EN: "EN",
	AR: "الانتقال إلى اللغة العربية",
	AreYouSureYouWantToLogOutThisDriverFromTheCurrentSession:"Are you sure you want to log out this driver from the current session ?",
	Help: "Help",
	ConfirmLogout: "Confirm logout ?",
	NoResultsFound: "No results found",
	VideoID: "Video ID",
	TextCopied: "Text copied",
	Date: "Date",
	SpacesAreNotAllowed: "Spaces Are not allowed!",
	InvalidEmail: "Invalid Email!",
	InvalidNumber: "Invalid Number!",
	InvalidName: "Invalid Name!",
	Required: "Required",
	UserNotFound: "User not found",
	UpdateSuccessful: "Update successful",
	UsernameOrPasswordIsMissing: "Username or Password is missing",
	UsernameIsMissing: "Username is missing",
	PasswordResetSuccessful: "Password reset successful",
	ReportedItemCannotBeCangedToOpenStatusAgain:"Reported item cannot be changed to open status again.",
	Classification: "Classification",
	VpElement: "VP Elements",
	Percentage: "Percentage",
	TrackID: "TrackID",
	GarbageContainerAvailability: "Garbage Container Availability",
	AvailableGarbageBins: "Available Garbage Bins",
	VpElementByDistrict: "VP Elements By District",
	ByStatus: "By Status",
	ByClass: "By Class",
	ActivateUser: "Activate User",
	Internal: "Internal",
	AssignmentRecordUpdatedSuccessfully: "Assignment record updated successfully",
	MismatchingScheduledDaysCountPlanneddatesCounts:"Mismatching scheduled days count & Planned dates counts",
	UpdatedSuccessfully: "Updated successfully",
	AddedSuccessfully: "Added successfully",
	PleaseSpecifyAtleastOneDayPlanToCreateASchedule:"Please specify atleast one day plan to create a schedule",
	PleaseClickOnTheMapToStartDrawingThePolygons:"Please click on the map to start drawing the polygons",
	PleaseCheckAllTheRequiredFields: "Please check all the required fields",
	PleaseCickMorePointsToCreateAnAreaShape:"Please click more points to create an area shape",
	Added: "Added",
	TooManyRequestsPleaseTryAgainLater:"Too many requests, please try again later",
	UnlinkSuccessful: "Unlink successful",
	Unlink: "Unlink",
	PleaseclickOnAtleastFourPointsToConstructAArea:"Please click on atleast four points to construct a area",
	DistanceCovered: "Distance Covered",
	MaxNumberValidationOfTen: "Valid number should have 10 digits",
	VolumeOfCmCube: "Volume of CM Cube above",
	VolumeOfCmPiles: "Volume of CM Piles above",
	VolumeOfCwOthers: "Volume of CW Others above",
	AreaOfGraffiti: "Area of Graffiti above",
	ActivateAutomation: "Activate Automation",
	ActivateCar: "Activate Car",
	ActivateDriver: "Activate Driver",
	ActivateDestination: "Activate Destination",
	NeededGarbageBins: "Needed Garbage Bins",
	H: "H",
	AutoOpenFunctionality: "Auto Open Functionality",
	GarbageBinEvery: "Garbage Bin Every",
	Meters: "Meters",
	SelectPeriod: "Select Period",
	CompareTo: "Compare To",
	PreviousPeriod: "Previous Period",
	CurrentPeriod: "Current Period",
	PreviousYear: "Previous Year",
	Class: "Class",
	Justification: "Justification",
	Page: "Page",
	Today: "Today",
	ReportNo: "ReportNo",
	Format: "Format",
	Yesterday: "Yesterday",
	NameOfTheUser: "Name of the User",
	NameOfAutomation: "Name of Automation",
	NameOfDestination: "Name of Destination",
	NameOfTheDrivers: "Name of the Driver",
	Reports: "Reports",
	AdvancedFilter: "Advanced Filter",
	PasswordResetHint: "Opt for a password with 8 characters, with 1 alphabet, 1 number, and 1 special character for added security",
	TemporaryDisabled: "This feature is temporarily disabled",
	Tracks: "Tracks",
	ExportExcel: "Export Excel",
	ExportPDF: "Export PDF",
	GenerateReport: "Generate Report",
	Pending: "pending",
	"vpelements-by-district": "VP Elements by District",
	Section: "Section",
	YourReportIsBeingGeneratedYouCanViewTheReportUnderSection:"Your report is being prepared, you can view it under",
	WorkerNotReady: "Temporary error, please reload the page",
	Report: "Report",
	"garbage-container-availability": "Garbage container availability",
	Failed: "Failed",
	Startat: "Start at",
	Endat: "End at",
	CarUpdatedSuccessfully: "Car updated successfully",
	CarCreatedSuccessfully: "Car created successfully",
	AreYouSureYouWantToUnlink: "Are you sure you want to unlink",
	From: "from",
	MaxAllowedPeriodIs14Days: "Note: Max allowed period is 14 days",
	SelectAll: "Select All",
	DeSelectAll: "DeSelect All",
	IncidentsPage: "Incidents Page",
	IncidentStatus: "Incident Status",
	SearchIncidentID: "Search for Incident ID",
	Incident: "Incident",
	Incidents: "Incidents",
	IncidentResults: "Incident Results",
	IncidentID: "Incident ID",
	IncidentLastSeen: "Last Seen",
	IncidentFirstSeen: "First Seen",
	Next: "Next",
	NextSighting: "Next Sighting",
	IncidentSightingID: "Sighting ID for Incident",
	FeatureUnderDevelopment:"This feature is currently under development, issues may occur",
	GarbageCracks: "Cracks",
	GarbageColor: "Color",
	GarbageBrokenLeg: "Broken Leg",
	GarbageContent: "Content",
	GarbageDented: "Dented",
	Isconforming: "Is Conforming",
	GarbageOverflowing: "Overflowing",
	GarbagePaint: "Paint",
	GarbageProvider: "Provider",
	GarbageShape: "Shape",
	AreaOfInterest: "Area of Interest",
	false: "No",
	true: "Yes",
	commercial: "Commercial",
	official: "Official",
	barrel: "Barrel",
	construction: "Construction",
	container: "Container",
	regular: "Regular",
	yes: "Yes",
	no: "No",
	empty: "Empty",
	full: "Full",
	GraffitiColor: "Suggested Wall-Paint Color",
	Moving: "Moving",
	VPIPage: "Visual Pollution Index",
	checkDualPeriod: "Compare with previous period",
	TrackerUnderDevelopment: "Tracker page is under development. Results temporarily limited to improve interactivity.",
	CoveragePage: "City Coverage Page",
	CoverageGreen: "Coverage over 75%",
	CoverageOrange: "Coverage over 50%",
	CoverageRed: "Coverage less than 50%",
	CarName: "Car Name",
	EditCar: "Edit Car",
	Recommendation: "Recommendation",
	ExternalIncidentsPage: "External Incidents Page",
	ExternalIncidentsResults: "External Incidents Results",
	SearchExternalIncidentsUUID: "Search for External Incidents ID",
	ExternalIncidentsID: "External Incidents ID",
	ExternalIncidents: "External Incidents",
	ReviewerPage: "Reviewer Page",
	PriorityAreas:"Priority Areas",
	Reviewer: "Reviewer",
	Accept: "Accept",
	Reject: "Reject",
	PreviousImage: "Previous Image",
	NextImage: "Next Image",
	ProfileInfo: "Profile Info",
	MyAccount: "My Account",
	CurrentPassword: "Current Password",
	ReEnterNewPassword: "Re-enter New Password",
	UpdatePassword: "Update Password",
	PasswordUpdateSuccessful: "Password Update Successful",
	Passwordsdoesnotmatch: "Passwords does not match",
	PasswordResetFailed: "Password reset failed",
	ThereIsNoDataAvailableAtTheMoment: "There is no data available at the moment",
	Temporary: "Temporary",
	Pleasefillmandatoryfields : "Please fill mandatory fields",
	DriverPerformance: "Driver Performance",
	DriverNameList: "Driver Name List",
	NoAreaOfInterest: "No Area Of Interest",
	NoSubMunicipalityAvailable: "No Sub Municipality Available",
	NoDistrictAvailable: "No District Available",
	DailyAssignmentPerUser: "Daily Assignment per User",
	GoToLatestIncidentReviewed: "Go to Latest Incident Reviewed",
	DateAndTimeReviewed: "Date and Time Reviewed",
	Progress: "Progress",
	NewReviewerPage: "New Reviewer Page",
	Accepted: "Accepted",
	Rejected: "Rejected",
	NotReviewed: "Not Reviewed",
	ReviewerSettings: "Reviewer settings",
	ManagePortalConfigurations: "Manage Portal Configurations",
	GeneralSettings: "General Settings",
	DefaultFilterConfidence: "Default filter confidence",
	AllowUsersToFilterByConfidence: "Allow users to filter by confidence",
	Previous: "Previous",
	IntegrationSettings: "Integration settings",
	NumberOfIncidents: "Number of incidents",
	Execute: "Execute",
	Time: "Time",
	SendImmediately: "Send Now",
	SendAtEveryWorkingDay: "Schedule Daily Send",
	ApiConnectionInfo: "API Connection Info",
	ConnectionUrl: "Connection URL",
	AuthHeaderTokenKey: "Auth header token key",
	AuthHeaderTokenValue: "Auth header token value",
	LastUpdatedAt: "Last updated at",
	CreatedBy: "Created by",
	ClientName: "Client name",
	ApiError: "API Error",
	ClassTypes: "Class types",
	Immediate: "Immediate",
	LastSentAt: "Last sent at",
	createdAt: "Created at",
	UseCredentialsForAuthentication: "Use credentials for authentication",
	LastUpdatedBy: "Last updated by",
	ViewLogs: "View logs",
	Logs: "Logs",
	PleaseWaitYourRequestIsBeingProcessed: "Please wait, your request is being processed",
	Success: "Success",
	LoginViaMunicipalityAccount:"Login Via Municipality Account",
	Pleaseenteronetimepassword:"Please enter OTP",
	NotReceivedOTP:"Not Received OTP ?",
	YouCanResendOTPWithIn:"You can resend OTP within",
	ResendOtp:"Resend OTP",
	seconds:"seconds",
	GoBack:"Go Back",
	OTPsent:"OTP sent Successfully",
	TwoFactorAuthenticationSuccessful:"Two Factor authentication successful",
	IncorrectOTP:"Incorrect OTP",
	ErrorVerifyingOTP:"Error verifying OTP",
}
