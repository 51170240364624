export const ar = {
	// General
	// Dashboard
	DashboardPage: "شاشة البيانات ومؤشرات الأداء",
	last14Days: "آخر 14 يومًا",
	Last7Days: "آخر 7 أيام",
	Last48hours: "آخر 48 ساعة",
	Last30Days: "آخر 30 يومًا",
	Last90Days: "آخر 90 يومًا",
	All: "الكل",
	OverAllSightingsFound: "إجمالي المشاهدات المكتشفة",
	SightingsThatAreNewlyFound: "المشاهدات المكتشفة حديثًا",
	SightingCountThatAreOpen: "المشاهدات المفتوحة",
	WorkHours: "ساعات العمل",
	SumofWorkingHoursByAllDrivers: "مجموع ساعات العمل لجميع السائقين",
	Distance: "المسافة",
	OverAllSumOfDistanceCoveredByAllDrivers: "مجموع المسافات التي قطعها جميع السائقين",
	SuperAdmin: "سوبر أدمن",
	Admin: "المدير",
	Supervisor: "المشرف",
	Employee: "الموظف",
	SightingVolume: "حجم المشاهدة",
	new: "جديدة",
	open: "مفتوحة",
	archived: "مؤرشفة",
	mislabeled: "غير صحيحة",
	New: "جديدة",
	Open: "مفتوحة",
	Archived: "مؤرشفة",
	Mislabeled: "غير صحيحة",
	Sunday: "الأحد",
	Monday: "الاثنين",
	Tuesday: "الثلاثاء",
	Wednesday: "الأربعاء",
	Thursday: "الخميس",
	Friday: "الجمعة",
	Saturday: "السبت",
	SubMunicipalities: "البلديات الفرعية",
	Completed: "مكتمل",
	District: "الحي",
	Name: "الاسم",
	//Sightings Page
	SightingsPage: "شاشة المشاهدات",
	Layout: "التصميم",
	Custom: "تخصيص",
	Search: "بحث",
	SearchSightingID: "بحث عن معرًف مشاهدة",
	Status: "الحالة",
	Type: "النوع",
	AdvancedSearch: "بحث متقدم",
	IncludeConformingGarbageBins: "إضافة حاويات القمامة المطابقة",
	Includeconformingbarriers: "إضافة الحواجز المطابقة",
	Polygon: "تحديد مضلع",
	SubMunicipality: "البلدية الفرعية",
	Confidence: "مستوى الثقة",
	Timestamp: "الزمن",
	Clear: "مسح",
	Apply: "تطبيق",
	TotalResults: "مجموع النتائج",
	Volumeofbuildingmaterials: "الحجم التقريبي لمواد البناء",
	Areaofgrafitti: "المساحة التقريبية للكتابة على الجدران",
	m2: "(متر مربع)",
	m3: "(متر مكعب)",
	ListView: "العرض في قائمة",
	manual: "بشكل يدوي",
	ManualTicket: "بشكل يدوي",
	automatic: "بشكل آلي",
	ID: "الرقم التعريفي",
	DateandTime: "التاريخ والوقت",
	AM: "صباحاً",
	PM: "مساءً",
	Longitude: "خط الطول",
	Latitude: "خط العرض",
	SpecialAttribute: "الخصائص الإضافية",
	Sighting: "المشاهدة",
	SightingID: "الرقم التعريفي للمشاهدة",
	TrainingModel: "النموذج التدريبي",
	CameraID: "الرقم التعريفي للكاميرا",
	Evidence: "البيانات المرجعية",
	Takemetotrack: "الانتقال إلى خريطة التسجيلات",
	Comments: "ملاحظات",
	AddComment: "أضف ملاحظة",
	Submit: "تسليم",
	Cancel: "إلغاء",
	Confirmdelete: "تأكيد الحذف؟",
	Areyousuretodeletetheitem: "هل أنت متأكد من رغبتك بحذف هذا العنصر؟",
	Yes: "نعم",
	No: "لا",
	January: "يناير",
	February: "فبراير",
	March: "مارس",
	April: "أبريل",
	May: "مايو",
	June: "يونيو",
	July: "يوليو",
	August: "أغسطس",
	September: "سبتمبر",
	October: "أكتوبر",
	November: "نوفمبر",
	December: "ديسمبر",
	Actions: "الإجراءات",
	SightingStatus: "حالة المشاهدة",
	Enteryourmessagehere: "قم بإدخال رسالتك هنا",
	Openas940Ticket: "فتح تذكرة على 940",
	Textcopied: "تم نسخ النص",
	NoPolygonFound: "لم يتم العثور على أي مضلع",
	Back: "الرجوع",
	// Tracks & Coverage Page
	Camera: "الكاميرا",
	SideCamLeft: "الكاميرا اليسرى",
	FrontCam: "الكاميرا الأمامية",
	SideCamRight: "الكاميرا اليمنى",
	Track: "المسار",
	SelectedTrack: "المسار المختار",
	TrackStart: "بداية المسار",
	TrackEndCurrentLocation: "نهاية المسار / الموقع الحالي",
	Ancient: "السابق",
	Recent: "الحالي",
	km: "كيلو متر",
	m: "متر",
	Start: "بداية المسار",
	End: "نهاية المسار / الموقع الحالي",
	AvgSpeed: "معدل السرعة",
	MaxSpeed: "أعلى سرعة",
	Duration: "المدة الزمنية",
	kmh: "كيلو متر/ ساعة",
	sec: "ثانية",
	// Live & Planning Page
	IntersectedTrack: "",
	PlannedTrack: "المسار المخطط",
	IntersectedPlannedTrack: "",
	Live: "البيانات الحية",
	OnlineDrivers: "السائقون المتصلون",
	AllDrivers: "جميع السائقين",
	PlannedDistance: "المسافة المخطط لها",
	Areas: "المناطق",
	Details: "التفاصيل",
	CompletedDistance: "مسافة مكتملة",
	ActiveDrivers: "السائقون النشطون",
	Planning: "التخطيط",
	StartDate: "تاريخ البداية",
	EndDate: "تاريخ النهاية",
	AddNewArea: "إضافة منطقة جديدة",
	TotalDistance: "إجمالي المسافة",
	AreaID: "الرقم المرجعي للمنطقة",
	SelectCategory: "اختر التصنيف",
	Calculate: "احسب",
	Schedules: "الجداول",
	AddNewSchedule: "إضافة جدول جديد",
	hrs: "ساعات",
	hr: "ساعة",
	Assignments: "المهام",
	AddAssignment: "إضافة مهمة",
	Driver: "السائقون المتصلون",
	ValidFrom: "تبدأ من",
	ValidTo: "تستمر حتى",
	Reportaproblem: "الإبلاغ عن مشكلة",
	// Settings Page
	Settings: "الإعدادات",
	SuggestPassword: "كلمة مرور مقترحة",
	UserManagement: "إدارة المستخدمين",
	Email: "البريد الإلكتروني",
	Mobile: "الجوال",
	ConfidenceRate: "مستوى الثقة",
	ShowConfidenceRate: "إظهار مستوى الثقة",
	SelectedRange: "اختيار المدى",
	Active: "فعال",
	LastSeen: "آخر مشاهدة",
	Add: "إضافة",
	AssignedAt: "تم الإسناد في",
	LastSent: "آخر إرسال",
	CurrentSentCount: "إجمالي عدد المرسل حتى الآن",
	DriverLoggedOutSuccessfully: "تم تسجيل خروج السائق بنجاح",
	AutomationSettings: "إعدادات الأتمتة",
	MaxSightingsPerDay: "الحد الأعلى لليوم الواحد",
	Usecustomlayout: "أستخدام تخطيط مخصص",
	LastRun: "آخر عملية تشغيل",
	Grid: "الشبكة 	",
	Destination: "رابط الوجهة Destination",
	Method: "الطريقة",
	URL: "الرابط",
	Cars: "السيارات",
	CarPlate: "لوحة السيارة",
	Manufacturer: "الجهة المصنعة",
	AllowMultiDrivers: "السماح بأكثر من سائق",
	Drivers: "السائقون",
	Regular: "اعتيادي",
	CarsAssignments: "إسناد السيارات",
	UserRole: "مستوى الصلاحية",
	ClassType: "الصنف",
	Rowsperpage: "عدد الصفوف لكل صفحة :",
	Of: "من",
	GID: "الرقم الحكومي",
	GovernmentID: "الرقم المرجعي الحكومي",
	UserUpdatedSuccessfully: "تم تحديث بيانات المستخدم بنجاح",
	UserCreatedSuccessfully: "تم إنشاء حساب المستخدم بنجاح",
	EditAutomationSetting: "تعديل إعدادات الأتمتة",
	NoDataAvailable: "لا توجد بيانات",
	Delete: "حذف",
	Edit: "تعديل",
	Download: "تحميل",
	EditUser: "تعديل بيانات المستخدم",
	// Login Page
	CityKlues: "سيتي كلوز",
	Username: "اسم المستخدم",
	Password: "كلمة المرور",
	Login: "تسجيل الدخول",
	TheSystemofSightinginRiyadh: "",
	RiyadhRegion: "منطقة الرياض",
	Municipality: "أمانة",
	Forgotmypassword: "نسيت كلمة المرور؟",
	Rememberme: "تذكرني",
	AllRightsReserved: "جميع الحقوق محفوظة",
	LoginSuccessful: "تم تسجيل الدخول بنجاح",
	Logout: "تسجيل الخروج",
	CarID: "الرقم التعريفي السيارة",
	SightingResults: "نتائج المشاهدات",
	NewPassword: "كلمة المرور الجديدة",
	Totalnumberofsightings: "إجمالي عدد المشاهدات",	
	NewSightings: "المشاهدات الجديدة",
	OpenSightings: "المشاهدات المفتوحة ",
	Percentageofscheduledtripsdistancecovered: "نسبة المسافة المقطوعة إلى إجمالي مسافة الرحلات المجدولة",
	Totaldrivingtime: "إجمالي مدة قيادة المركبات",
	Distancetravelledinkilometres: "المسافة المقطوعة بالكيلومتر",
	Total: "إجمالي",
	LeftCam: "الكاميرا اليسرى",
	RightCam: "الكاميرا اليمنى",
	PoweredbyKluesforAItechnologiesandIoTSquaredforinfrastructureandoperations:"مشغل بواسطة كلوز لتقنيات الذكاء الاصطناعي، وآي أو تي سكويرد للبنية التحتية والتشغيل",
	Metabase: "لوحة ذكاء الأعمال",
	SubMunicipalityDistrict: "البلدية الفرعية / الحي",
	Sightings: "المشاهدات",
	TracksPage: "شاشة التسجيلات والمسارات",
	DrawPolygon: "ارسم مضلع",
	Filters: "خيارات التصفية",
	VolumeOfConstructionWaste: "حجم مخلفات البناء",
	PDF: "بي دي إف",
	Excel: "إكسل",
	Create: "إنشاء",
	AddUser: "إضافة مستخدم",
	AddAutomationSetting: "إضافة مستخدم",
	AreaGreaterThan: "المساحة أكبر من",
	VolumeGreaterThan: "الحجم أكبر من",
	AddCar: "إضافة سيارة",
	Plate: "اللوحة",
	Model: "الموديل",
	Year: "السنة",
	Update: "تحديث",
	Token: "الرمز",
	TokenHeader: "اسم الرمز",
	AddDestination: "إضافة رابط الوجهة Destination",
	EditDestination: "تعديل رابط الوجهة Destination",
	ScheduleID: "الرقم المرجعي للجدول",
	Planned: "المسافة المخطط لها",
	Accomplished: "تم الاكتمال",
	Tracking: "التسجيلات",
	DeletedSuccessfully: "تم الحذف بنجاح",
	Grafitti: "كتابة على الجدران",
	EN: "الانتقال إلى اللغة الإنجليزية",
	AR: "الانتقال إلى اللغة العربية",
	AreYouSureYouWantToLogOutThisDriverFromTheCurrentSession: "هل أنت متأكد من رغبتك في تسجيل خروج هذا السائق؟",
	Help: "المساعدة",
	ConfirmLogout: "تأكيد تسجيل الخروج ؟",
	NoResultsFound: "لم يتم العثور على أية نتائج",
	VideoID: "الرقم التعريفي الفيديو",
	TextCopied: "تم نسخ النص",
	Date: "التاريخ",
	SpacesAreNotAllowed: "!استخدام زر المسافة غير متاح هنا",
	InvalidEmail: "!البريد الالكتروني غير صالح",
	InvalidNumber: "!الرقم غير صالح",
	InvalidName: "!معلومات الاسم غير صالحة",
	Required: "مطلوب",
	UserNotFound: "لم يتم العثور على المستخدم",
	UpdateSuccessful: "تم التحديث بنجاح",
	UsernameOrPasswordIsMissing: "اسم المستخدم أو كلمة المرور غير مكتملة أو غير متوفرة",
	UsernameIsMissing: "اسم المستخدم غير مكتمل أو غير متوفر",
	PasswordResetSuccessful: "تم تحديث كلمة المرور بنجاح",
	ReportedItemCannotBeCangedToOpenStatusAgain: "لا يمكن إعادة حالة المشاهدة للعنصر إلى (مفتوحة)",
	Classification: "التصنيف",
	VpElement: "عناصر التشوه البصري",
	Percentage: "النسبة المئوية",
	TrackID: "الرقم التعريفي للتسجيل",
	GarbageContainerAvailability: "توفّر حاوية نفايات",
	AvailableGarbageBins: "حاويات النفايات المتوفرة",
	VpElementByDistrict: "عناصر التشوه البصري حسب الحي",
	ByStatus: "حسب الحالة",
	ByClass: "حسب التصنيف",
	ActivateUser: "تنشيط المستخدم",
	Internal: "Internal",
	AssignmentRecordUpdatedSuccessfully: "تم تحديث سجلات المهام بنجاح",
	MismatchingScheduledDaysCountPlanneddatesCounts: "عدد الأيام المجدولة وعدد الأيام المخطط لها غير متطابقين",
	UpdatedSuccessfully: "تم التحديث بنجاح",
	AddedSuccessfully: "تمت الإضافة بنجاح",
	PleaseSpecifyAtleastOneDayPlanToCreateASchedule: "الرجاء تحديد خطة من يوم واحد على الأقل لإنشاء جدول",
	PleaseClickOnTheMapToStartDrawingThePolygons: "الرجاء الضغط على الخريطة للبدء برسم المضلع",
	PleaseCheckAllTheRequiredFields: "الرجاء التحقق من تعبئة جميع الحقول",
	PleaseCickMorePointsToCreateAnAreaShape: "الرجاء الضغط على نقاط أكثر لرسم المساحة المطلوبة",
	Added: "تمت الإضافة",
	TooManyRequestsPleaseTryAgainLater: "توجد طلبات كثيرة، الرجاء المحاولة مرة أخرى لاحقاً",
	UnlinkSuccessful: "تم فك الارتباط بنجاح",
	Unlink: "افصل السائق",
	PleaseclickOnAtleastFourPointsToConstructAArea: "الرجاء تحديد أربعة نقاط على الأقل لرسم المساحة المطلوبة",
	DistanceCovered: "المسافة المغطاة",
	MaxNumberValidationOfTen: "الرقم المدخل يجب أن يحتوي على ١٠ خانات",
	VolumeOfCmCube: "الحجم التقريبي لمواد البناء - المضلعة",
	VolumeOfCmPiles: "الحجم التقريبي لمواد البناء - الأكوام",
	VolumeOfCwOthers: "الحجم التقريبي لمخلفات البناء",
	AreaOfGraffiti: "المساحة التقريبية للكتابة على الجدران",
	ActivateAutomation: "الأتمتة النشطة",
	ActivateCar: "تنشيط السيارة",
	ActivateDriver: "تنشيط السائق",
	ActivateDestination: "تفعيل رابط الوجهة",
	NeededGarbageBins: "عدد حاويات القمامة المطلوبة",
	H: "ساعات",
	AutoOpenFunctionality: "خاصية الفتح الآلي",
	GarbageBinEvery: "حاوية قمامة كل",
	Meters: "مترًا",
	SelectPeriod: "اختر الفترة",
	CompareTo: "المقارنة مع",
	PreviousPeriod: "الفترة السابقة",
	CurrentPeriod: "الفترة الحالية",
	PreviousYear: "السنة السابقة",
	Class: "التصنيف",
	Justification: "المبررات",
	Page: "الصفحة",
	Today: "اليوم",
	ReportNo: "رقم التقرير",
	Format: "الصيغة",
	Yesterday: "أمس",
	NameOfTheUser: "اسم المستخدم",
	NameOfAutomation: "اسم الأتمتة",
	NameOfDestination: " اسم رابط الوجهة Destination",
	NameOfTheDrivers: "اسم السائق",
	Reports: "التقارير",
	AdvancedFilter: "التخصيص المتقدم",
	PasswordResetHint: ".اختر كلمة مرور مكونة من 8 أحرف، مع أبجدية واحدة ورقم واحد وحرف خاص واحد لمزيد من الأمان",
	TemporaryDisabled: "هذه الخاصية معطلة مؤقتًا",
	Tracks: "المسارات",	
	ExportExcel: "تصدير إكسل",
	ExportPDF: "تصدير بي دي إف",
	GenerateReport: "استخراج تقرير",
	Pending: "جاري العمل",
	"vpelements-by-district": "المشاهدات بحسب الأحياء",	
	Section: "قسم",
	YourReportIsBeingGeneratedYouCanViewTheReportUnderSection: "جاري إصدار تقريرك، يمكنك الاطلاع على التقرير تحت تقرير",
	WorkerNotReady: "خطأ مؤقت، فصلًا قم بتحديث الصفحة",
	Report: "التقرير",
	"garbage-container-availability": "توفّر حاوية نفايات",
	Failed: "فشل",
	Startat: "البدء في",
	Endat: "الانتهاء في",
	CarUpdatedSuccessfully: "تم تحديث المركبة بنحاج",
	CarCreatedSuccessfully: "تم إنشاء المركبة بنجاح",
	AreYouSureYouWantToUnlink: "هل أنت متأكد أنك تريد إلغاء الارتباط",
	From: "من",
	MaxAllowedPeriodIs14Days: "ملحوظة: الحد الأقصى المسموح به هو 14 يومًا",
	SelectAll: "تحديد الكل",
	DeSelectAll: "إلغاء اختيار الكل" ,
	IncidentsPage: "شاشة الحالات",
	IncidentStatus: "طور الحالة",
	SearchIncidentID: "البحث عن معرّف حالة",
	Incident: "الحالة",
	Incidents: "الحالات",
	IncidentResults: "نتائج الحالات",
	IncidentID: "الرقم التعريفي للحالة",
	IncidentLastSeen: "آخر مشاهدة",
	IncidentFirstSeen: "أول مشاهدة",
	Next: "التالي",	
	NextSighting: "المشاهدة التالية",
	IncidentSightingID: "الرقم التعريفي للمشاهدة التابعة للحالة",
	FeatureUnderDevelopment: "هذه الصفحة تحت التطوير، وقد تقع بعض الأخطاء أو تتعرض لبطء",
	GarbageCracks: "تشققات",
	GarbageColor: "اللون",
	GarbageBrokenLeg: "أرجل متضرره",
	GarbageContent: "المحتوى",
	GarbageDented: "ضرر هيكلي",
	Isconforming: "مطابقة للمواصفات",
	GarbageOverflowing: "ممتلئة للغاية",
	GarbagePaint: "الدهان",
	GarbageProvider: "النوع",
	GarbageShape: "الشكل",
	AreaOfInterest: "المناطق ذات الأولوية",
	false: "لا",
	true: "نعم",
	commercial: "تجاري",
	official: "بلدية",
	barrel: "برميل",
	construction: "حاوية بناء",
	container: "حاوية اعتيادية",
	regular: "إعتيادية",
	yes: "نعم",
	no: "لا",
	empty: "فارغة",
	full: "ممتلئة",
	GraffitiColor: "اللون المقترح للدهان",
	Moving: "متحركة",
	VPIPage: "شاشة مؤشر التشوهات البصرية",
	checkDualPeriod: "المقارنة مع الفترة السابقة",
	TrackerUnderDevelopment: "شاشة المسارات قيد التحسين، وعدد النتائج الظاهره محدود مؤقتًا لتحسين الجودة التفاعلية",
	CoveragePage: "شاشة تغطية المدينة",
	CoverageGreen: "نسبة تغطية تتجاوز 75%",
	CoverageOrange: "نسبة تغطية تتجاوز 50%",
	CoverageRed: "نسبة تغطية أقل من 50%",
	CarName: "اسم السيارة",
	EditCar: "تعديل تفاصيل السيارة",
	Recommendation: "توصية",
	ExternalIncidentsPage: "صفحة الحالات الواردة خارجيًا",
	ExternalIncidentsResults: "نتائج الحالات الواردة",
	SearchExternalIncidentsUUID: "البحث عن معرّف حالة خارجية",
	ExternalIncidentsID: "معرّف الحالة الخارجية",
	ExternalIncidents: "صفحة الحالات الواردة خارجيًا",
	ReviewerPage: "صفحة المراجعين",
	PriorityAreas:"المجالات ذات الأولوية",
	Reviewer: "صفحة المراجعين",
	Accept: "قبول",
	Reject: "رفض",
	PreviousImage: "الصورة السابقة",
	NextImage: "الصورة القادمة",
	ProfileInfo: "معلومات الملف الشخصي",
	MyAccount: "الحساب الخاص بي",
	CurrentPassword: "كلمة المرور الحالية",
	ReEnterNewPassword: "أعد إدخال كلمة المرور الجديدة ",
	UpdatePassword: "تحديث كلمة المرور",
	PasswordUpdateSuccessful: "تم تحديث كلمة المرور بنجاح",
	Passwordsdoesnotmatch: "كلمات المرور غير متطابقة",
	PasswordResetFailed: "فشلت إعادة تعيين كلمة المرور",
	ThereIsNoDataAvailableAtTheMoment: "لا توجد بيانات متاحة في الوقت الحالي",
	Temporary: "مؤقت",
	Pleasefillmandatoryfields : "يرجى ملء الحقول الإلزامية",
	DriverPerformance: "أداء السائق",
	DriverNameList: "قائمة اسماء السائقين",
	NoAreaOfInterest: "لا توجد مناطق ذات أولوية",
	NoSubMunicipalityAvailable: "لا توجد بلدية فرعية",
	NoDistrictAvailable: "لا يوجد حي متاح",
	DailyAssignmentPerUser: "المهام اليومية لكل مستخدم",
	GoToLatestIncidentReviewed: "انتقل إلى آخر حالة تمت مراجعتها",
	DateAndTimeReviewed: "تاريخ و وقت المراجعة",
	Progress: "المراجع",
	NewReviewerPage: "صفحة المراجعين الجدد",
	Accepted: "مقبوله",
	Rejected: "مرفوضة",
	NotReviewed: "لم تتم مراجعتها",
	ReviewerSettings: "اعدادات المراجع",
	ManagePortalConfigurations: "التحكم بإعدادت النظام",
	GeneralSettings: "الإعدادات العامة",
	DefaultFilterConfidence: "مستوى الثقة الافتراضية للتصفية",
	AllowUsersToFilterByConfidence: "السماح للمستخدمين بالتصفية حسب مستوى الثقة",
	Previous: "سابق",
	IntegrationSettings: "اعدادات التكامل",
	NumberOfIncidents: "عدد الحالات",
	Execute: "تنفيذ",
	Time: "الوقت",
	SendImmediately: "إرسال الان",
	SendAtEveryWorkingDay: "جدولة الإرسال اليومي",
	ApiConnectionInfo: "API معلومات اتصال",
	ConnectionUrl: "رابط الاتصال",
	AuthHeaderTokenKey: "مفتاح المصادقة",
	AuthHeaderTokenValue: "قيمة المصادقة",
	LastUpdatedAt: "اخر تحديث",
	CreatedBy: " أنشأ من قبل",
	ClientName: "اسم العميل",
	ApiError: "API خطأ في ال",
	ClassTypes: " نوع التشوه البصري",
	Immediate: "مباشر",
	LastSentAt: "١- آخر إرسال",
	createdAt: "٢- تم الإنشاء",
	UseCredentialsForAuthentication: "٣- استخدم بيانات الدخول للمصادقة.",
	LastUpdatedBy: "٤- آخر تحديث عن طريق",
	ViewLogs: "٥- عرض السجلات",
	Logs: "٦- السجلات",
	PleaseWaitYourRequestIsBeingProcessed: "Please wait, your request is being processed",
	Success: "Success",
	LoginViaMunicipalityAccount:"تسجيل الدخول عبر حساب البلدية",
	Pleaseenteronetimepassword:"الرجاء إدخال كلمة المرور مرة واحدة",
	NotReceivedOTP:"لم يتم استلام كلمة المرور لمرة واحدة",
	YouCanResendOTPWithIn:"يمكنك إعادة إرسال كلمة المرور لمرة واحدة داخل",
	ResendOtp:"إعادة إرسال كلمة المرور لمرة واحدة",
	seconds:"ثواني",
	GoBack:"عُد",
	OTPsent:"تم إرسال كلمة المرور لمرة واحدة",
	TwoFactorAuthenticationSuccessful:"نجحت المصادقة الثنائية",
	IncorrectOTP:"كلمة مرور لمرة واحدة غير صحيحة",
	ErrorVerifyingOTP:"خطأ في التحقق من كلمة المرور لمرة واحدة",

}
