<template>
	<v-app>
		<v-main :style="`background: ${$vuetify.theme.dark ? '' : '#eef0f3'}`">
			<navigation-drawer v-if="!['login'].includes($route.name)"></navigation-drawer>
			<v-progress-linear fixed top v-show="loading" indeterminate color="primary darken-2"></v-progress-linear>
			<layout></layout>
			<router-view></router-view>
		</v-main>
	</v-app>
</template>
<script>
import("./assets/style.css")
import store from "@/store"
import { i18n } from "./lang/config"
import cookie from "vue-cookie"
import { mapGetters } from "vuex"
export default {
  name: "App",
  data () {
    return {
      loading: false,
    }
  },
  components: {
    "navigation-drawer": () => import("./components/NavigationDrawer.vue"),
    "layout": () => import("./components/Layout.vue"),
  },
  created () {
    this.$store.commit("updateUserData")
    if (!this.systemconfig && this.userdata?._id) this.$store.dispatch("updateSystemConfigStateValue")

    // shortcuts handler
    document.onkeydown = (e) => {
      e = e || window.Event
      const code = e.which || e.keyCode // Get key code
      if (![27].includes(code) && (!e.altKey)) return
      if (window.document.URL.includes("/login")) return
      e.preventDefault()
      e.stopPropagation()
      switch (code) {
        case 76: // { alt + l }
          store.commit("logout")
          break
        case 68: // { alt + d }
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          break
        case 82: // { alt + r }
          this.changelang()
          break
      }
    }
  },
  watch: {
    "$vuetify.rtl": {
      handler (value) {
        this.$eventBus.$emit("onrtlchange", value)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["systemconfig"]),
  },
  mounted () {
    this.$root.$on("loading", (value) => {
      this.loading = value
    }),
    this.$eventBus.$on("loading", (value) => {
      this.loading = value
    })
  },
  methods: {
    /**
     * Changes the language preference of the user interface and updates the stored preference.
     *
     * @function
     * @example
     * // To change the language preference:
     * changelang();
     */
    changelang () {
      const langpref = (i18n.locale === "en") ? "ar" : "en"
      const isUserFromMS = JSON.parse(cookie.get("isUserFromMS"))
		if(!isUserFromMS) this.$_execute("put", `users/${this.userdata._id}`, { langpref })
      localStorage.setItem(`${process.env.VUE_APP_APP}_langpref`, langpref)
      i18n.locale = langpref
      this.$vuetify.rtl = !this.$vuetify.rtl
    },
  },
  beforeDestroy () {
    this.$root.$off("loading")
  },
}
</script>
