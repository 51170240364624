import socket from "./config"

let socketid = null

socket.on("connect", () => {
	socketid = socket.id
	console.info("SocketId: ", socket.id)
})

// heartbeat
setInterval(() => socket.emit("heartbeat"), 2000)

socket.on("disconnect", () => {
	console.info("Socket disconnected: ", socketid)
	socketid = null
})

export default {
	install(Vue) {
		Vue.prototype.$socket = socket
	},
}

// error-handling
socket.on("connect_failed", (error) => {
	console.error("Sorry, there seems to be an connection failed with the connection!",error)
})

socket.on("connect_error", (error) => {
	console.error("Sorry, there seems to be error with the connection!" , error)
})

socket.on("Connect_timeout", (error) => {
	console.error("Sorry, it seems to be timeout of socket connection!", error)
})

socket.on("error", (error) => {
	console.error("Sorry, it seems to be an error with the connection!", error)
})

socket.on("reconnect_failed", (error) => {
	console.error("Sorry, Reconnection with the connection is failed!", error)
})
